//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Copyright extends Component {
    //Element
    render() {
        //Element
        const { ID, Context } = this.props
        //Element
        const Content = React.createElement( "aside", { className: "float-start w-100 p-0 m-0 j05q38" },
            //Element
            React.createElement( "div", { className: "float-start w-50 p-0 m-0 p-0 m-0 z2596n" },
                //Element
                React.createElement( "p", { className: "float-start w-auto p-0 m-0 text-truncate f07622", itemProp: "creditText" },
                    //Element
                    Context
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-50 p-0 m-0 z7h704" },
                //Element
                React.createElement( "ul", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 y43to7" },
                    //Element
                    React.createElement( "li", { className: "float-start w-auto" },
                        //Element
                        React.createElement( "a", { className: "float-start w-auto p-0 m-0 e76r8a", title: "İptal ve İade Şartları", href: "/iptal-ve-iade-sartlari" },
                            //Element
                            "İptal ve İade Şartları"
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Copyright.propTypes = {
    ID      : propTypes.string.isRequired,
    Context : propTypes.string.isRequired
}
//Element
export default Copyright;