//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";

//Element
class Return extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Web Tasarım : Hizmet İptali ve İade Şartları - Benozgurtosun.com"
        //Element
        Description.content = "Web Tasarım Sözleşmesi'nin İptali ve İade Şartları Hakkında Bu Bölümden Bilgi Edinebilirsiniz."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/iptal-iade-sartlari"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Web Tasarım : Hizmet İptali ve İade Şartları - Benozgurtosun.com"
        //Element
        Description.content = "Web Tasarım Sözleşmesi'nin İptali ve İade Şartları Hakkında Bu Bölümden Bilgi Edinebilirsiniz."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
        //Element
        URL.content         = "https://benozgurtosun.com/iptal-iade-sartlari"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Web Tasarım : Hizmet İptali ve İade Şartları - Benozgurtosun.com"
        //Element
        Description.content = "Web Tasarım Sözleşmesi'nin İptali ve İade Şartları Hakkında Bu Bölümden Bilgi Edinebilirsiniz."
        //Element
        Image.content       = "https://cdn.benozgurtosun.com/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Web Tasarım : Hizmet İptali ve İade Şartları - Benozgurtosun.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Web Tasarım Sözleşmesi'nin İptali ve İade Şartları Hakkında Bu Bölümden Bilgi Edinebilirsiniz."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 bg-transparent border-0 g65z5u", itemScope: "itemScope", itemType: "https://schema.org/Thing" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e918a8" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 x63112" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 o49df7", title: "Web Tasarım Sözleşmesinin İptali ve İade Şartları", itemProp: "name alternateName" },
                                    //Element
                                    "Web Tasarım Hizmetleri'nin; ",
                                    //Element
                                    React.createElement( "strong", {},
                                        //Element
                                        "İptali ve İade"
                                    ),
                                    //Element
                                    " Şartları"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 u04m7q", itemProp: "description" },
                                    //Element
                                    "Sunduğum Hizmetlerin Kalitesini ve Müşteri Memnuniyetini Daima Ön Planda Tutarım.",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "Kaliteli Hizmet(ler) Sunmayı Hedeflerken İhtiyaçlara Uygun Olmayan Durumlarda İade",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    " Sürecinin Sorunsuz Şekilde Gerçekleşmesinin Sağlamak Benim İçin Önceliktir."
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 y0422f" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"6 offset-md-4" },
                            //Element
                            React.createElement( "ul", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 z493ea" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Web Tasarım Hizmetlerinin İptali ve İade Şartları", href: "https://cdn.benozgurtosun.com/sartlar-ve-kosullar/iptal-ve-iade-sartlari.pdf" },
                                        //Element
                                        "Web Tasarım Hizmetlerinin İptali ve İade Şartları"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Return;