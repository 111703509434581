//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import Corporation from "./Component/Tab/Corporation/Default";
//Install
import Manifesto from "./Component/Tab/Corporation/Manifesto";
//Install
import Bank from "./Component/Tab/Corporation/Bank";
//Install
import Career from "./Component/Tab/Corporation/Career";
//Install
import Legal from "./Component/Tab/Corporation/Legal";
//Install
import Return from "./Component/Tab/Corporation/Return";
//Install
import Reference from "./Component/Tab/Reference/Default";
//Install
import Competition from "./Component/Tab/Competition/Default";
//Install
import Price from "./Component/Tab/Price/Default";
//Install
import Partner from "./Component/Tab/Partner/Default";
//Install
import Contact from "./Component/Tab/Contact/Default";
//Install
import Information from "./Component/Tab/Information/Default";
//Install
import Successful from "./Component/Tab/Contact/Successful";
//Install
import Domain from "./Component/Tab/Business/Domain";
//Install
import Hosting from "./Component/Tab/Business/Hosting";
//Install
import SSL from "./Component/Tab/Business/SSL";
//Install
import Email from "./Component/Tab/Business/Email";
//Install
import SEO from "./Component/Tab/Business/SEO";
//Install
import Startup from "./Component/Tab/Performance/Startup";
//Install
import Small from "./Component/Tab/Performance/Small";
//Install
import Medium from "./Component/Tab/Performance/Medium";
//Install
import Company from "./Component/Tab/Performance/Company";
//Install
import New from "./Component/Tab/Customer/New";
//Install
import Brand from "./Component/Tab/Customer/Brand";
//Install
import Sales from "./Component/Tab/Customer/Sales";
//Element
import Facebook from "./Component/Tab/Ad/Facebook";
//Install
import Google from "./Component/Tab/Ad/Google";
//Install
import Instagram from "./Component/Tab/Ad/Instagram";
//Install
import Yandex from "./Component/Tab/Ad/Yandex";
//Install
import Youtube from "./Component/Tab/Ad/Youtube";
//Install
import Error from "./Component/Tab/Error/Default";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    //Element
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/kurumsal" element={ <Corporation /> }></Route>
          <Route path="/manifesto" element={ <Manifesto /> }></Route>
          <Route path="/odeme" element={ <Bank /> }></Route>
          <Route path="/kariyer" element={ <Career /> }></Route>
          <Route path="/kisisel-verilerin-korunmasi" element={ <Legal /> }></Route>
          <Route path="/iptal-ve-iade-sartlari" element={ <Return /> }></Route>
          <Route path="/referanslar" element={ <Reference /> }></Route>
          <Route path="/rekabet-programi" element={ <Competition /> }></Route>
          <Route path="/ucretlendirme" element={ <Price /> }></Route>
          <Route path="/partner" element={ <Partner /> }></Route>
          <Route path="/hemen-ulasin" element={ <Contact /> }></Route>
          <Route path="/on-bilgilendirme-talebi" element={ <Information /> }></Route>
          <Route path="/tesekkur-ederim" element={ <Successful /> }></Route>
          <Route path="/e-isletme/domain" element={ <Domain /> }></Route>
          <Route path="/e-isletme/hosting" element={ <Hosting /> }></Route>
          <Route path="/e-isletme/ssl" element={ <SSL /> }></Route>
          <Route path="/e-isletme/e-posta" element={ <Email /> }></Route>
          <Route path="/e-isletme/seo" element={ <SEO /> }></Route>
          <Route path="/maksimum-performans/yeni-isletmeler-icin" element={ <Startup /> }></Route>
          <Route path="/maksimum-performans/kucuk-isletmeler-icin" element={ <Small /> }></Route>
          <Route path="/maksimum-performans/orta-olcekli-isletmeler-icin" element={ <Medium /> }></Route>
          <Route path="/maksimum-performans/sirketler-icin" element={ <Company /> }></Route>
          <Route path="/isletme-rehberligi/bilinirligi-artirin" element={ <Brand /> }></Route>
          <Route path="/isletme-rehberligi/satislari-artirin" element={ <Sales /> }></Route>
          <Route path="/isletme-rehberligi/yeni-musteriler-edinin" element={ <New /> }></Route>
          <Route path="/reklam-ver/facebook" element={ <Facebook /> }></Route>
          <Route path="/reklam-ver/google" element={ <Google /> }></Route>
          <Route path="/reklam-ver/youtube" element={ <Youtube /> }></Route>
          <Route path="/reklam-ver/yandex" element={ <Yandex /> }></Route>
          <Route path="/reklam-ver/instagram" element={ <Instagram /> }></Route>
          <Route path="/404" element={ <Error /> }></Route>
          <Route path="*" element={ <Navigate to="/404" /> } />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;
